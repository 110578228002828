import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from './store';
import { useMeQuery } from 'common/services/endpoints/auth';
import { auth } from 'common/utils/firebase';
import { api } from 'common/store/api';
import { setAuthToken } from 'common/store/slices/authSlice';
import { PRE_REDIRECT_ROUTE_ROLE_KEY } from 'common/constants';
import { removeCompanyData } from 'common/store/slices/companySlice';
import { useRouter } from 'next/router';

function useAccount() {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.auth.authToken);
  const initialized = useAppSelector((state) => state.auth.initialized);

  const {
    data: user,
    isLoading,
    isSuccess,
    error,
  } = useMeQuery(undefined, {
    skip: !token,
  });

  const logout = useCallback(async () => {
    if (user?.role) {
      localStorage.setItem(PRE_REDIRECT_ROUTE_ROLE_KEY, `${user?.role}`);
    }
    dispatch(removeCompanyData());

    await auth.signOut();
    dispatch(setAuthToken(null));
    dispatch(api.util.resetApiState());
    router.push('/login');
  }, [dispatch, router, user]);

  const isAuthError = error && 'status' in error && error.status === 401;

  return {
    currentUserId: user?.id,
    user,
    // server might be down, they are authenticated but error is thrown
    isAuthenticated: Boolean(
      (token && isSuccess) || (token && error && !isAuthError),
    ),
    isLoading: isLoading || !initialized,
    logout,
    error,
  };
}

export default useAccount;
