import authReducer from 'common/store/slices/authSlice';
import companyReducer from 'common/store/slices/companySlice';
import { ReactNode } from 'react';
import StoreProvider from 'common/providers/StoreProvider';
import chatbotReducer from '../../slices/chatbotSlice';

interface StoreWrapperProps {
  children: ReactNode;
}

const reducers = {
  auth: authReducer,
  company: companyReducer,
  chatbot: chatbotReducer,
};

const StoreWrapper = ({ children }: StoreWrapperProps) => {
  return <StoreProvider reducers={reducers}>{children}</StoreProvider>;
};

export default StoreWrapper;
