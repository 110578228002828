import Script from 'next/script';
import useRoles from 'common/hooks/useRoles';

export default function TawkToWidget() {
  const { isVendor, isPropertyManager } = useRoles();
  if (isVendor || isPropertyManager) {
    return (
      <>
        <Script id="tawk-to">
          {`var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
              var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
              s1.async=true;
              s1.src='https://embed.tawk.to/66a923d91601a2195b9e4c6b/1i42b82oq';
              s1.charset='UTF-8';
              s1.setAttribute('crossorigin','*');
              s0.parentNode.insertBefore(s1,s0);
            })();
        `}
        </Script>
      </>
    );
  }
  return null;
}
