import 'common/utils/sentry';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import StoreWrapper from 'components/Global/StoreWrapper';
import AuthProvider from 'common/components/auth/AuthProvider';
import BuildUpdater from 'common/components/BuildUpdater';
import { ToastProvider } from 'styleguide/src/components/Toasts/ToastContext';
import TawkToWidget from 'components/vendor/global/TawkToWidget';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Wreno</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>
      <StoreWrapper>
        <AuthProvider>
          <ToastProvider>
            <Component {...pageProps} />
            <TawkToWidget />
            <BuildUpdater />
          </ToastProvider>
        </AuthProvider>
      </StoreWrapper>
    </>
  );
}
