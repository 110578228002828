export enum AccountRole {
  COORDINATOR = 'COORDINATOR',
  ADMIN = 'ADMIN',
  VENDOR = 'VENDOR',
  PROPERTY_MANAGER = 'PROPERTY_MANAGER',
}

export type UserType = Exclude<AccountRole, 'ADMIN' | 'COORDINATOR'>;

enum ExtraAccountRoles {
  ALL = 'ALL',
}

export type AllPossibleAccountRoles = ExtraAccountRoles | AccountRole;
